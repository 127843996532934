<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
 Update User

       <feather-icon icon="Edit2Icon" />
      </h4>
      
      
    </div>
    <validation-observer ref="addFormvalidate">
    <b-form class="mt-2" style="width: 60%" @submit.prevent="save">
        <b-row>
          
          <b-col md="12">
            <b-form-group
              label=" Name"
              label-for="blog-edit-title"
              class="mb-2"
            >
             <validation-provider
                  #default="{ errors }"
                  name=" Name"
                  rules="required"
                >
              <b-form-input id="blog-edit-title" v-model="addForm.name" />
                 <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
          <b-col md="12">
            <b-form-group
              label="Email"
              label-for="blog-edit-slug"
              class="mb-2"
            >
            
               <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
              <b-form-input
                id="blog-edit-slug"
                v-model="addForm.email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
                 <b-col md="12">
            <b-form-group
              label="password"
              label-for="blog-edit-title"
              class="mb-2"
            >
             <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
              <b-form-input id="blog-edit-title" v-model="addForm.password" />
                 <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
         
         <b-col md="12">
            <b-form-group
              label="Role"
              label-for="blog-edit-category"
              class="mb-2"
            >
               <validation-provider
                  #default="{ errors }"
                  name="Role"
                  rules=""
                >
              <v-select
                id="blog-edit-category"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                v-model="addForm.role"
               
                :options="rolesList"
                
              />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
               @click="$router.go(-1)"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import { required, email } from '@validations'
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref, reactive,computed } from "@vue/composition-api";
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import Vue from "vue";
export default {
  setup() {
    // const store = useStore();
        const refInputEl = ref(null);
      const refInputE2 = ref(null);
    const refPreviewEl = ref(null);
     const refPreviewE2 = ref(null);

      const Tabimage = ref("media/svg/files/blank-image.svg");
           const { route } = useRouter()
      const id = route.value.params.id
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)
    console.log(id)
      store.dispatch('setting/GetUser',{id})
        .then(response => {
         
           store.commit('setting/GET_USER', response?.data.data)
          })
          const rolesList=ref([])
 store.dispatch('setting/AllRoles')
        .then(response => {
          console.log("response",response.data.data)
          rolesList.value=response.data.data
        })
 
    const Form = computed(() => store.state.setting.user);
    const addForm=reactive(Form)
 
   
   const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      (base64) => {
        console.log("dd")
        refPreviewE2.value.src = base64;
      }
    );
    
     const addFormvalidate=ref()

    const save = () => {
      
      console.log(addForm)
      const formData = new FormData();

    
        
         addFormvalidate.value.validate().then(success => {
        if (success) {
        

    formData.append("email", addForm.value.email);
formData.append("name", addForm.value.name);
formData.append("role", addForm.value.role);

formData.append("password", addForm.value.password);
formData.append("_method",'put');
      console.log("formData",formData)
           store.dispatch('setting/UpdateUser',{id,formData})
        .then(response => {
           Vue.swal({
                       title: "User Update ",
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
          
        }).catch((error) => {
      
            Vue.swal({
              title: "",
              text: `${error.response.data.message}`,
              icon: "error",
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
         
      console.log("alt_header_image", addForm);
        }})
    };
    return {
    refInputEl,
      refPreviewEl,
      refInputE2,
       refPreviewE2,
      inputImageRendererTab,
     
      Tabimage,
      addForm,
      inputImageRenderer,
      addFormvalidate,
      id,
      Form,
      addForm,
      rolesList,
   
     

      save,
       required, email
    };
  },

  components: {
    BCard,
    BMedia,
  ValidationProvider, ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
